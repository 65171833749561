import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EXECUTE_COPY_AGAIN } from '../../store/clipboard/const';

const CopyText = () => {
  const dispatch = useDispatch();
  const spanRef = useRef(null);
  const { copyText, onCopySuccess, onCopyError, executeCopy } = useSelector(
    (state) => state.clipboard
  );

  console.log('executeCopy', executeCopy);
  useEffect(() => {
    if (spanRef.current) {
      spanRef.current.click();
    }
  }
  , [copyText]);
  // Copiar al portapapeles usando la API moderna
  const copyToClipboard = async () => {

    if (!copyText) {
      return;
    }

    try {
      setTimeout(async () => {
        document.getElementById('copyText').select();
        await navigator.clipboard.writeText(copyText);
      }, 0);
      if (typeof onCopySuccess === 'function') {
        onCopySuccess(copyText);
      }
    } catch (error) {
      console.error('Error al copiar:', error);
      fallbackCopyText(copyText);
      if (typeof onCopyError === 'function') {
        onCopyError(error, copyText);
      }
    }
  };
  // Método alternativo usando un textarea temporal (para iOS Safari)
  const fallbackCopyText = (copyText) => {
    try {
      const textArea = document.createElement("textarea");
      textArea.value = copyText;
      textArea.style.position = "absolute";
      textArea.style.left = "-9999px"; // Ocultarlo de la vista
      document.body.appendChild(textArea);
      textArea.select();
      const success = document.execCommand("copy"); // Mé
      //todo antiguo, aún soportado en Safari
      document.body.removeChild(textArea);
      console.log("Texto copiado con fallback:", copyText);
      if (success === "function") {
        onCopySuccess(copyText);
      }
    } catch (error) {
      console.error("Error en fallbackCopyText:", error);
      if (typeof onCopyError === "function") {
        onCopyError(error, copyText);
      }
    }
  };

  // Se ejecuta cuando el usuario hace clic en el botón de copia
  const handleCopy = () => {
    copyToClipboard(copyText);
    dispatch({
      type: EXECUTE_COPY_AGAIN,
      executeCopy: false,
    });
  };

  return (
    <div className='position-absolute' style={{ top: '-100%' }}>
      <input id='copyText' value={copyText || ''} readOnly />
      <button
        ref={spanRef}
        className='btn'
        id='copyTextTrigger'
        onClick={handleCopy}
      >
        <img src='assets/clippy.svg' alt='Copy to clipboard' />
      </button>
    </div>
  );
};

export default CopyText;
