import React, {useMemo} from 'react'
import {
    IconArrowRight,
    ListItem,
    Avatar
} from '@Knowledge-OTP/znk-ui-components'
import {TabPane, TabContent} from 'reactstrap'
import RenderInputOption from '../../Components/RenderInputOption'
// import Advisor from './SetAdvisor'
import Head from './SetHead'
import useAddCounselorModal from './Modal/AddCounselorModal/useAddCounselorModal'
import useTranslation from '../../../../../../i18n/useTranslation'

const Team = (props) => {
    const {className, plan, setData} = props
    const {open, close} = useAddCounselorModal()
    const {activeTab, councelorName, councelorId} = useMemo(() => {
        if(plan?.counselorData) {
            return {
                activeTab: 'selected',
                councelorName: `${plan.counselorData?.firstName} ${plan.counselorData?.lastName}`,
                councelorId: plan.counselorData?.teamUserId || plan.counselorData?.id
            }
        }
        return {activeTab: 'no-selected'}
        // eslint-disable-next-line
    }, [plan?.counselorData])

    const openAddCounselor = (defaultCounselors = []) => {
        open(
            (counselors) => {
                if(counselors?.length > 0) setData({
                    councelor: counselors[0].id,
                    counselorData: {
                        ...counselors[0], teamUserId: counselors[0].id 
                    }
                })
                close()
            }, false, defaultCounselors
        )
    }
    const { translate } = useTranslation()

    return (
        <div className={`m-0 p-0 d-flex flex-column ${className}`}>
            <span className={`h4 font-weight-bold text-title`}>Team</span>
            <RenderInputOption key={'planName'} label={null}>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={'selected'}>
                        <ListItem
                            className='py-1 appears-animation'
                            id='x-0'
                            label={null}
                            onClick={() => {
                                openAddCounselor([councelorId])
                            }}
                            Left={
                                <div className='d-flex align-items-center'>
                                    <Avatar
                                        name={councelorName}
                                        named={false}
                                        uri={null}
                                        size='avatar-medium'
                                    />
                                    <label className='ml-2 h4'>
                                        {councelorName} <span className={`m-0 p-0 text-gray`}>(Counselor)</span>
                                    </label>
                                </div>
                            }
                            Right={
                                <div className={`hover-icon`}><IconArrowRight/></div>
                            }
                        />
                        {/* <Advisor plan={plan} setData={setData} /> */}
                    </TabPane>
                    <TabPane tabId={`no-selected`}>
                        <span className={`btn-link`} onClick={openAddCounselor}>{translate('counseling.newplan.ssc')}</span> 
                    </TabPane>
                </TabContent>
                {/* <Advisor plan={plan} setData={setData} /> */}
                <Head plan={plan} setData={setData} />
            </RenderInputOption>
        </div>
    )
}

export default Team